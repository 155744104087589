import { appScope$ } from '../app/app'
import { toast } from 'react-toastify'

export const errorHandler = (type: string, error: any) => {
  //TODO: - Implement proper error handling for api and internal app errors
  console.log('error handled by errorHandler', error)
  appScope$.next(false)
}

export const showErrorToast = (error: any, message: string) => {
  const title = error?.response?.data?.title
  const detail = title
    ? `${title} (${error?.response?.data?.detail})`
    : ` (${error?.response?.data?.detail})`
  const errorMessage = `${message} : ${detail}`
  toast.error(errorMessage, { toastId: 'error' })
}
