import './error.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

const errorDescription = new URLSearchParams(window.location.search).get(
  'error_description'
)
const message =
  'Your login attempt has failed. Please contact an administrator with further questions.'
const subMessage = `When requesting help, please provide your email address and the following error code: ${errorDescription ?? 'unknown'}`
export const ErrorPage = () => {
  return (
    <div className="error-page">
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        size={'xl'}
        className="error-icon"
      />
      <h1>Authentication Error</h1>
      <h2>{message}</h2>
      <h2>{subMessage}</h2>
    </div>
  )
}
