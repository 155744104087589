import React from 'react'
import { useConfirmHook } from './hooks'
import { Button } from '@progress/kendo-react-buttons'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

library.add(faCheck, faTimes)

type ConfirmationComponentProps = {
  message: string
  onAbort: any
  onConfirm: any
}

const ConfirmationComponent = (props: ConfirmationComponentProps) => {
  const confirmProps = useConfirmHook()

  return (
    <Dialog>
      <p data-testid="confirm-message">{props.message}</p>
      <DialogActionsBar>
        <Button
          data-testid="abort-button"
          onClick={() => {
            confirmProps.handleAbort(props.onAbort)
          }}
          title={'Abort'}
        >
          <FontAwesomeIcon icon="times" />
        </Button>
        <Button
          data-testid="confirm-button"
          themeColor={'primary'}
          onClick={() => {
            confirmProps.handleConfirm(props.onConfirm)
          }}
          title={'Confirm'}
        >
          <FontAwesomeIcon icon="check" />
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default ConfirmationComponent
