import React from 'react'
import './styles.scss'
import { Loader } from '@progress/kendo-react-indicators'

const LoaderComponent = (props: any) => {
  return (
    <div className="k-centered" data-testid="loader">
      {props?.showAccessMsg && (
        <h1 className="loader-header">
          We are sorry, but you do not have access to this application. Please
          contact 7SIGNAL Support.
        </h1>
      )}
      {!props?.showAccessMsg && (
        <Loader
          className="k-centered loader"
          type="infinite-spinner"
          size="large"
        />
      )}
    </div>
  )
}

export default LoaderComponent
