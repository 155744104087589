export interface Content {
  content?: Instance[]
  empty?: boolean
  first?: boolean
  last?: boolean
  number?: number
  numberOfElements?: number
  pageable?: any
  size?: number
  sort?: any
  totalElements?: number
  totalPages?: number
}

export interface Org {
  id: string
  name: string
}

export interface Eye {
  id: string
  macAddress: string
  serialNumber: string
  organization: Org
}

export interface Instance {
  id: string
  defaultPort: number
  hostname: string
  ipAddress: string
  prodPort: number
}

export interface InstancePut {
  defaultPort: any
  hostname: any
  ipAddress: any
  prodPort: any
}

export interface InstancesProps {
  data: Instance[]
  sort: any
  sortChange: any
  SELECTED_FIELD: any
  selectedState: any
  idGetter: any
  onSelectChange: any
  onSelectionChange: any
  onHeaderSelectionChange: any
  addSapphireInstance: any
  deleteSapphireInstance: any
  handleConfirmDelete: any
  confirmDeleteMessage: string
  setConfirmDeleteMessage?: any
  showDialog: any
  toggleDialog: () => any
  _setData?: any
  _setPage?: any
  _setPageSizeValue?: any
  _hasTruthyValue?: any
  shouldDisableButton: any
  fetchData: () => any
  accessToken: any
  pageSizeValue: number
  pageChange: (e) => any
  page: any
  isEditing?: boolean
  setIsEditing?: any
  setEditData?: any
  isCheckboxSelected
  setIsCheckboxSelected
  parseSort?: (e) => any
  editSapphireInstance: any
  editData: Instance
  isSelected?: any
  setIsSelected?: any
}

export enum System {
  Eye = 'eyes',
  Instance = 'instances',
  Organization = 'organizations',
}
