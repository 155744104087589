import { TextBoxProps } from '@progress/kendo-react-inputs'
import { useCallback, useEffect, useState } from 'react'
import { InstancesDialogType } from './types'
import {
  createInstance,
  updateInstanceById,
} from '../../../../services/instances/instances'

export const useInstancesDialogHook = (props: any): InstancesDialogType => {
  const [defaultPort, setDefaultPort] = useState<TextBoxProps['value']>('')
  const [hostname, setHostname] = useState<TextBoxProps['value']>('')
  const [ipAddress, setIpAddress] = useState<TextBoxProps['value']>('')
  const [prodPort, setProdPort] = useState<TextBoxProps['value']>('')

  const [changes, setChanges] = useState<boolean>(false)
  const handleChange = useCallback(
    (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const sapphireInstanceObj = {
        defaultPort: setDefaultPort,
        hostname: setHostname,
        ipAddress: setIpAddress,
        prodPort: setProdPort,
      }

      const value = event.target.value
      if (value.length === 0) setChanges(false)
      if (sapphireInstanceObj[field]) {
        sapphireInstanceObj[field](value)
        setChanges(true)
      }
    },
    []
  )

  const resetState = () => {
    setDefaultPort('')
    setHostname('')
    setIpAddress('')
    setProdPort('')
    setChanges(false)
    props.clearCheckbox()
    props.toggleDialog()
  }

  const handleClear = useCallback((field: string) => {
    const sapphireInstancesObj = {
      defaultPort: () => setDefaultPort(''),
      hostname: () => setHostname(''),
      ipAddress: () => setIpAddress(''),
      prodPort: () => setProdPort(''),
    }
    setChanges(false)
    sapphireInstancesObj[field]()
  }, [])

  const create = async () => {
    const data = { defaultPort, hostname, ipAddress, prodPort }
    await createInstance(props.accessToken, data).catch((e) => {
      console.log('error: unable to create', e.message)
    })
    props.toggleDialog()
    resetState()
    props.fetchData()
    setChanges(false)
  }

  const update = async () => {
    const data = { defaultPort, hostname, ipAddress, prodPort }
    await updateInstanceById(props.accessToken, props.editData.id, data).catch(
      (e) => {
        console.log('error: unable to create', e.message)
      }
    )
    props.toggleDialog()
    resetState()
    props.fetchData()
    setChanges(false)
  }

  useEffect(() => {
    if (props.isEditing) {
      setDefaultPort(props.editData.defaultPort)
      setHostname(props.editData.hostname)
      setIpAddress(props.editData.ipAddress)
      setProdPort(props.editData.prodPort)
    }
  }, [props.isEditing, props.editData])

  return {
    fetchData: props.fetchData,
    toggleDialog: props.toggleDialog,
    accessToken: props.accessToken,
    isEditing: props.isEditing,
    showDialog: props.showDialog,
    clearCheckbox: props.clearCheckbox,
    defaultPort,
    hostname,
    ipAddress,
    prodPort,
    handleClear,
    create,
    handleChange,
    changes,
    resetState,
    update,
    setChanges,
    setDefaultPort,
    setHostname,
    setIpAddress,
    setProdPort,
  }
}
