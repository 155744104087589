import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import {
  InputClearValue,
  InputSeparator,
  TextBox,
} from '@progress/kendo-react-inputs'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@progress/kendo-react-buttons'
import './styles.scss'
import { InstancesDialogType } from './types'

export const ClearInputComponent = (props, type) => (
  <React.Fragment>
    {props[type] !== '' && (
      <InputClearValue
        data-testid={'clear-input'}
        onClick={() => props.handleClear(type)}
      >
        <FontAwesomeIcon icon={faXmark} />
      </InputClearValue>
    )}
    <InputSeparator />
  </React.Fragment>
)

const DialogInstancesComponent = (props: InstancesDialogType) => {
  return (
    <Dialog
      data-testid={'dialog'}
      height={500}
      width={450}
      onClose={() => props.toggleDialog}
    >
      <h2 className="dialog-header-text">
        {props.isEditing
          ? 'EDIT SAPPHIRE INSTANCE'
          : 'CREATE SAPPHIRE INSTANCE'}
      </h2>
      <form onSubmit={(e) => e.preventDefault()} data-testid={'form'}>
        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'defaultPort'}>Default Port</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={props.defaultPort}
            onChange={(e) => {
              props.handleChange('defaultPort', e)
            }}
            placeholder="Ex: 6###5"
            suffix={() => ClearInputComponent(props, 'defaultPort')}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'hostname'}>Hostname</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={props.hostname}
            onChange={(e) => {
              props.handleChange('hostname', e)
            }}
            placeholder="Ex: example.example.com"
            suffix={() => ClearInputComponent(props, 'hostname')}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'ipAddress'}>Ip Address</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={props.ipAddress}
            onChange={(e) => {
              props.handleChange('ipAddress', e)
            }}
            placeholder="Ex: 192.###.1.1"
            suffix={() => ClearInputComponent(props, 'ipAddress')}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'prodPort'}>Prod Port</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={props.prodPort}
            onChange={(e) => {
              props.handleChange('prodPort', e)
            }}
            placeholder="Ex: 1##4"
            suffix={() => ClearInputComponent(props, 'prodPort')}
          />
        </fieldset>
      </form>
      <DialogActionsBar>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => {
            if (props.isEditing) {
              if (props.clearCheckbox) props.clearCheckbox()
              props.toggleDialog()
            } else {
              props.resetState()
            }
          }}
        >
          Cancel
        </Button>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => (props.isEditing ? props.update() : props.create())}
          disabled={!props.changes}
        >
          {props.isEditing ? 'Update' : 'Submit'}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default DialogInstancesComponent
