import { ExcelExport } from '@progress/kendo-react-excel-export'
import { createRef, useCallback } from 'react'
import { BehaviorSubject } from 'rxjs'

export const excelDownload$ = new BehaviorSubject<any>(null)

export const useExcelHook = () => {
  const _exporter = createRef<ExcelExport>()

  const excelExport = useCallback(() => {
    const isExcelReady = _exporter?.current?.props?.data?.length ?? 0 >= 1
    if (isExcelReady) {
      _exporter.current!.save()
    }
  }, [_exporter])

  excelDownload$.subscribe((res) => {
    if (res !== null) {
      excelExport()
    }
  })

  return {
    _exporter,
    excelExport,
  }
}
