import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import {
  InputClearValue,
  InputSeparator,
  TextBox,
} from '@progress/kendo-react-inputs'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { EyeDialogType } from './types'
import { Button } from '@progress/kendo-react-buttons'
import './styles.scss'
import DropDownListComponent from '../../../../components/dropdown'

const DialogEyeComponent = (props: EyeDialogType) => {
  return (
    <Dialog
      data-testid={'dialog'}
      height={500}
      width={450}
      onClose={() => props.toggleDialog}
    >
      <h2 className="dialog-header-text">
        {props.isEditing ? 'EDIT SAPPHIRE EYE' : 'CREATE SAPPHIRE EYE'}
      </h2>
      <form onSubmit={(e) => e.preventDefault()} data-testid={'form'}>
        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'macAddress'}>Mac Address</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={props.macAddress}
            onChange={(e) => {
              props.handleChange('macAddress', e)
            }}
            placeholder="Ex: 00-1A-C5-1A-7C-00"
            suffix={() => (
              <React.Fragment>
                {props.macAddress !== '' && (
                  <InputClearValue
                    onClick={() => props.handleClear('macAddress')}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </InputClearValue>
                )}
                <InputSeparator />
              </React.Fragment>
            )}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'serialNumber'}>Serial Number</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={props.serialNumber}
            onChange={(e) => {
              props.handleChange('serialNumber', e)
            }}
            placeholder="Ex: JK123YINAO"
            suffix={() => (
              <React.Fragment>
                {props.serialNumber !== '' && (
                  <InputClearValue
                    onClick={() => props.handleClear('serialNumber')}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </InputClearValue>
                )}
                <InputSeparator />
              </React.Fragment>
            )}
          />
        </fieldset>
        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'organization'}>Organization</div>
          <DropDownListComponent
            data={props.organizationList}
            textField={'name'}
            value={props.organization}
            onChange={(e) => props.handleChange('organization', e)}
          />
        </fieldset>
      </form>
      <DialogActionsBar>
        <Button
          data-testid={'cancel-btn'}
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => {
            if (props.isEditing) {
              if (props.clearCheckbox) props.clearCheckbox()
              props.toggleDialog()
            } else {
              props.resetState()
            }
          }}
        >
          Cancel
        </Button>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => (props.isEditing ? props.update() : props.create())}
          disabled={!props.changes}
        >
          {props.isEditing ? 'Update' : 'Submit'}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default DialogEyeComponent
