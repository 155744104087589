import { TextBoxProps } from '@progress/kendo-react-inputs'
import React, { useCallback, useEffect, useState } from 'react'
import { OrganizationsProps } from '../../Types'
import {
  createOrganization,
  updateOrganizationById,
} from '../../../../services/organizations/organizations'
import { getAllInstances } from '../../../../services/instances/instances'
import { Instance } from '../../../Instances/Types'

export const useOrganizationsDialogHook = (
  props: any
): OrganizationsProps.DialogType => {
  const [name, setName] = useState<TextBoxProps['value']>('')
  const [caratOrgName, setCaratOrgName] = useState<TextBoxProps['value']>('')
  const [instance, setInstance] = useState<Instance | undefined>()
  const [changes, setChanges] = useState<boolean>(false)
  const [instanceList, setInstanceList] = useState<any>([])

  const handleChange = useCallback(
    (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const organizationsObj = {
        name: setName,
        caratOrgName: setCaratOrgName,
        instance: setInstance,
      }

      const value = event.target.value
      if (value.length === 0) setChanges(false)
      if (organizationsObj[field]) {
        organizationsObj[field](value)
        setChanges(true)
      }
    },
    []
  )
  const resetState = () => {
    setName('')
    setCaratOrgName('')
    setInstance(undefined)
    setChanges(false)
    props.clearCheckbox()
    props.toggleDialog()
  }

  const handleClear = useCallback((field: string) => {
    const organizationsObj = {
      name: () => setName(''),
      caratOrgName: () => setCaratOrgName(''),
      instance: () => setInstance(undefined),
    }
    setChanges(false)
    organizationsObj[field]()
  }, [])

  const create = async () => {
    const instanceId = instanceList.find(
      (inst) => inst.hostname === instance?.hostname
    )
    const data = { name, caratOrgName, instanceId: instanceId?.id }
    await createOrganization(props.accessToken, data).catch((e) => {
      console.log('error: unable to create', e.message)
    })
    props.toggleDialog()
    resetState()
    props.fetchData()
    setChanges(false)
  }

  const update = async () => {
    const instanceId = instanceList.find(
      (inst) => inst.hostname === instance?.hostname
    )
    const data = { name, caratOrgName, instanceId: instanceId?.id }
    await updateOrganizationById(
      props.accessToken,
      props.editData.id,
      data
    ).catch((e) => {
      console.log('error: unable to update', e.message)
    })
    props.toggleDialog()
    resetState()
    props.fetchData()
    setChanges(false)
  }
  const instanceData = useCallback(async () => {
    if (props.accessToken) {
      const response: any =
        (await getAllInstances(props.accessToken, 0, 1000, [`hostname,asc`])) ??
        []
      if (response?.data?.content?.length > 0) {
        const mappedData = response?.data?.content.map(({ id, hostname }) => ({
          id,
          hostname,
        }))
        setInstanceList(mappedData)
      }
    } else {
      return
    }
  }, [])

  useEffect(() => {
    if (props.isEditing === true) {
      setName(props?.editData?.name)
      setCaratOrgName(props?.editData?.caratOrgName)
      setInstance(props?.editData.instance)
    }
  }, [props.isEditing, props.editData])

  useEffect(() => {
    if (props.showDialog === true) {
      const fetchData = async () => {
        await instanceData()
      }
      fetchData()
    }
  }, [props.showDialog])

  return {
    fetchData: props.fetchData,
    toggleDialog: props.toggleDialog,
    accessToken: props.accessToken,
    isEditing: props.isEditing,
    showDialog: props.showDialog,
    clearCheckbox: props.clearCheckbox,
    resetState,
    name,
    caratOrgName,
    handleClear,
    create,
    handleChange,
    changes,
    update,
    setCaratOrgName,
    setName,
    instance,
    setInstance,
    setChanges,
    instanceList,
    instanceData,
  }
}
