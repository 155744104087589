import React, { useEffect } from 'react'

/*
 * HELPER FUNCTIONS GO HERE:
 * AND SHOULD BE EXPORTED FOR TESTING PURPOSES
 *
 * HELPER FUNCTIONS SHOULD NAMING CONVENTION SHOULD
 * END WITH HELPER
 *
 * i.e -> testHelper
 */

export const useSevenSignalGridHook = (props: any) => {
  useEffect(() => {
    // Make API call
    return () => {}
  }, [props.data])

  // other component logic or functions

  return {
    // This return object is what gets passed as props to the component
    data: props.data,
  }
}
