import axios from 'axios'
import { InstancePut, System } from '../../pages/Instances/Types'
import { toast } from 'react-toastify'
import { config } from '../../commonResources/config'
import { errorHandler, showErrorToast } from '../error/error'

const baseUrl = `${config.hostUrl}/api-v1`

export const getAllInstances = async (
  accessToken: string | null,
  page: number,
  size: number,
  sort?: any
) => {
  try {
    return await axios.get(
      `${baseUrl}/${System.Instance}?page=${page}&size=${size}&sort=${sort}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
  } catch (error: any) {
    if (error?.response?.status === 401) {
      errorHandler('Unauthorized', error)
    }
    showErrorToast(error, 'Error fetching instances')
  }
}

export const getInstancesById = async (accessToken: any, id: string) => {
  return await axios.get(`${baseUrl}/${System.Instance}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const createInstance = async (
  accessToken: string,
  newInstance: InstancePut
) => {
  try {
    const response = await axios.post(
      `${baseUrl}/${System.Instance}`,
      newInstance,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    toast.success('Successfully created Instance', { toastId: 'success' })
    return response
  } catch (error) {
    console.error('Error:', error)
    showErrorToast(error, 'Error creating instance')
  }
}

export const deleteInstanceById = async (
  accessToken: string | null,
  id: string
) => {
  try {
    const response = await axios.delete(`${baseUrl}/${System.Instance}/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    toast.success('Successfully deleted Instance', {
      toastId: 'success',
    })
    return response
  } catch (error) {
    console.error('Error:', error)
    showErrorToast(error, 'Error deleting instance')
  }
}

export const updateInstanceById = async (
  accessToken: string | null,
  id: string,
  data
) => {
  try {
    const response = await axios.put(
      `${baseUrl}/${System.Instance}/${id}`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    toast.success('Successfully updated Instance', {
      toastId: 'success',
    })
    return response
  } catch (error) {
    console.error('Error with PUT request:', error)
    showErrorToast(error, 'Error updating instance')
  }
}
