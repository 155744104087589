import React from 'react'
import './styles.scss'
import { EyeExcelType } from './types'
import { useExcelHook } from './hooks'
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export'

const ExcelComponent = (props: EyeExcelType) => {
  const excelHook = useExcelHook()

  return (
    <ExcelExport
      data-testid={'excel-table'}
      data={props.data}
      collapsible={true}
      fileName="Eyes.xlsx"
      ref={excelHook._exporter}
    >
      {props?.fields?.map((field, idx) => (
        <ExcelExportColumn key={idx} field={field.name} title={field.title} />
      ))}
    </ExcelExport>
  )
}

export default ExcelComponent
