import { useCallback, useContext, useEffect, useState } from 'react'
import { getter, SortDescriptor } from '@progress/kendo-data-query'
import {
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import {
  deleteSapphireEyeById,
  getSapphireEyesWithOptions,
  getAllSapphireEyes,
} from '../../services/eyes/eyes'
import { EyesProps, SapphireEyes } from './Types'
import { AccessTokenContext } from '../../commonResources/AccessTokenContext'
import { asyncCurryHelper, parseSort } from '../../services/app/app'

import { excelDownload$ } from '../../components/excel/hooks'
import { getAllOrganizations } from '../../services/organizations/organizations'
import { TextBoxProps } from '@progress/kendo-react-inputs'
import { toast } from 'react-toastify'
import { useGlobalContext } from '../../commonResources/GlobalContextProvider'

export const SapphireEyeHelper: any = {
  itemsToDelete: [],
  pagination: {
    page: 0,
    pages: 1,
    perPage: 20,
    total: 20,
  },
  hasAccessToken: false,
  bulkRequestId: '',
  itemSelected: false,
  filters: [],
}

export const useEyesHook = (): EyesProps => {
  const accessToken = useContext<string | null>(AccessTokenContext)
  SapphireEyeHelper.hasAccessToken = accessToken !== null
  const [data, setData] = useState<SapphireEyes[]>([])
  const [sort, setSort] = useState<Array<SortDescriptor>>([
    { field: '', dir: 'asc' },
  ])

  const [isSelected, setIsSelected] = useState<any>(null)
  const [page, setPage] = useState<any>({ take: 20, page: 0 })
  const [pageSizeValue, setPageSizeValue] = useState<number>(0)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [editData, setEditData] = useState<SapphireEyes>({
    id: '',
    macAddress: '',
    organization: { id: '', name: '' },
    serialNumber: '',
    createdAt: '',
    updatedAt: '',
  })
  const [organization, setOrganization] = useState<TextBoxProps['value']>('')
  const [organizationList, setOrganizationList] = useState<any>([])
  const [showBulk, setShowBulk] = useState<boolean>(false)
  const [bulkRequest, setBulkRequest] = useState<any>({})
  const DATA_ITEM_KEY: string = 'id'
  const SELECTED_FIELD: string = 'selected'
  const idGetter = getter(DATA_ITEM_KEY)
  const [filter, setFilter] = useState({})
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState('')
  const { eyesContext } = useGlobalContext()

  const setSearchOptions = (field: string, newValue: any) => {
    const idx = SapphireEyeHelper.filters.findIndex(
      (item) => item.field === field
    )
    if (idx !== -1) SapphireEyeHelper.filters.splice(idx, 1, newValue)
    if (idx === -1) SapphireEyeHelper.filters.push(newValue)
  }

  // This type should be CompositeFilterDescriptor ... but there is some sort of ts issue
  const handleFilter = async (e: any) => {
    try {
      setFilter(e)
      if (e === null) SapphireEyeHelper.filters = []
      if (e?.filters[0]?.value?.length >= 3) {
        setSearchOptions('filters', {
          field: 'filters',
          value: e?.filters,
        })
      }

      const res = await asyncCurryHelper(getSapphireEyesWithOptions)(
        accessToken,
        e?.filters,
        page,
        sort
      )
      setData(res?.data?.content)
      setPageSizeValue(res?.data.pagination.total)
    } catch (e: any) {
      toast.error(e?.response?.response?.data?.detail)
    }
  }

  const makeBulkRequestErrorMessage = (error: any): string => {
    const detail =
      error?.response?.response?.data?.detail ?? 'Error uploading CSV'
    const errorCount =
      error?.response?.response?.data?.validationErrorCount ?? 0

    if (errorCount > 0) {
      const firstFive = error?.response?.response?.data?.validationErrors
        .slice(0, 5)
        .map((x) => `Line ${x.lineNumber} - ${x.message}`)
      const additionalErrors = errorCount - 5
      const additionalErrorsString =
        additionalErrors > 0 ? `and ${additionalErrors} more errors` : ''
      return `${detail} : ${firstFive.join(', ')} ${additionalErrorsString}`
    } else {
      return detail
    }
  }

  const handleBulkRequest = (e) => {
    if (e?.response?.status === 201) {
      toast.success(e?.response?.response?.message)
      fetchData()
      toggleBulk()
      setBulkRequest({
        hasError: false,
        msg: e?.response?.response?.message,
      })
    } else {
      setBulkRequest({
        hasError: true,
        msg: makeBulkRequestErrorMessage(e),
      })
    }
  }

  const handleBeforeBulkRequest = (e) => {
    e.additionalData = {
      file: e.files[0]?.getRawFile(),
    }
    e.headers = {
      Authorization: `Bearer ${accessToken}`,
    }
  }

  const sortChange = async (event: GridSortChangeEvent) => {
    let response
    try {
      const sort = parseSort(event.sort)
      if (SapphireEyeHelper.filters?.some((x) => x.field === 'filters')) {
        response = await asyncCurryHelper(getSapphireEyesWithOptions)(
          accessToken,
          SapphireEyeHelper.filters.find((x) => x.field === 'filters').value,
          {
            page: page.page,
            take: 20,
          },
          event.sort
        )
      } else {
        response = await asyncCurryHelper(getAllSapphireEyes)(
          accessToken,
          page.page,
          page.take,
          sort
        )
      }
      setData(response?.data.content)
      setSort(event.sort)
      event.sort.forEach((item) => {
        setSearchOptions('sort', {
          field: 'sort',
          value: [
            {
              field: item.field,
              value: item.dir,
            },
          ],
        })
      })
    } catch (error) {
      console.log('Unable to sort Sapphire Eyes:', error)
    }
  }

  const fetchData = async () => {
    if (SapphireEyeHelper.hasAccessToken) {
      try {
        const sortData = parseSort(sort)
        const res = await asyncCurryHelper(getAllSapphireEyes)(
          accessToken,
          page.page,
          page.take,
          sortData
        )

        setData(res?.data.content)
        setPageSizeValue(res?.data.pagination.total)
      } catch (error) {
        console.error('Error fetching SapphireEyes:', error)
      }
    }
  }

  const organizationData = async () => {
    try {
      const res = await asyncCurryHelper(getAllOrganizations)(
        accessToken,
        0,
        1000,
        [`name,asc`]
      )
      if (res?.data?.content?.length > 0) {
        const mappedData = res?.data?.content.map(({ id, name }) => ({
          id,
          name,
        }))
        mappedData.push({ id: null, name: 'No Organization Specified' })
        SapphireEyeHelper.bulkRequestId = mappedData[0].id
        setOrganization(mappedData[0].name)
        setOrganizationList(mappedData)
      }
    } catch (error) {
      console.error('Error fetching Organization:', error)
    }
  }

  useEffect(() => {
    fetchData()
    organizationData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const excelDownload = () => {
    excelDownload$.next({})
  }

  const addSapphireEye = () => {
    setIsEditing(false)
    setShowDialog(true)
  }

  const editSapphireEye = () => {
    setShowDialog(true)
    setIsEditing(true)
  }

  const deleteSapphireEye = async () => {
    try {
      for (const item of eyesContext.itemsSelected) {
        await deleteSapphireEyeById(accessToken, item.id)
      }
      fetchData()
    } catch (error) {
      console.error('Error deleting SapphireEye:', error)
    }
  }

  const normalizeSort = (sort: SortDescriptor[]) => {
    if (sort[0]?.field) {
      const field =
        sort[0]?.field === 'organization.name'
          ? 'organizationName'
          : sort[0]?.field
      return `${field},${sort[0]?.dir}`
    } else {
      return 'asc'
    }
  }

  const pageChange = async (e) => {
    const currentPage = Math.floor(e.page.skip / e.page.take) + 1
    const page = currentPage - 1
    const pageSort = normalizeSort(sort)
    let response

    try {
      if (SapphireEyeHelper.filters?.some((x) => x.field === 'filters')) {
        response = await asyncCurryHelper(getSapphireEyesWithOptions)(
          accessToken,
          SapphireEyeHelper.filters.find((x) => x.field === 'filters').value,
          {
            page: page,
            take: 20,
          },
          sort
        )
      } else {
        response = await asyncCurryHelper(getAllSapphireEyes)(
          accessToken,
          page,
          20,
          pageSort
        )
      }

      setData(response?.data.content)
      setPageSizeValue(response?.data.pagination.total)
      setPage({
        ...e.page,
        ...response?.data.pagination,
        take: 20,
      })

      setSearchOptions('page', {
        field: 'page',
        value: page,
      })
    } catch (error) {
      console.error('Error fetching Page:', error)
    }
  }

  const toggleDialog = useCallback(() => {
    setShowDialog(!showDialog)
  }, [showDialog])

  const toggleBulk = useCallback(() => {
    setBulkRequest({
      hasError: false,
      msg: '',
    })
    setShowBulk(!showBulk)
  }, [showBulk])

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent | any) => {
      const { isChecked, dataItem } = event
      const { itemsToDelete } = SapphireEyeHelper

      SapphireEyeHelper.itemsToDelete = isChecked
        ? [...itemsToDelete.filter((item) => item.id !== dataItem.id), dataItem]
        : itemsToDelete.filter((item) => item.id !== dataItem.id)

      return SapphireEyeHelper.itemsToDelete
    },
    []
  )

  const hasTruthyValue = (obj) => {
    const array: any = Object.values(obj).filter(Boolean)
    return array.length === 1
  }

  const shouldDisableButton = () => {
    return SapphireEyeHelper.itemsToDelete.length === 0
  }

  const shouldDisableEdit = () => {
    return eyesContext.itemsSelected.length > 1
  }

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target
      const checked = checkboxElement.checked
      const newSelectedState = {}

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked
      })
      SapphireEyeHelper.itemsToDelete = event.dataItems
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleConfirmDelete = (objectData: any) => {
    let newDeleteMessage = 'Continue to delete ' + objectData.macAddress + '?'
    setConfirmDeleteMessage(newDeleteMessage)
  }

  return {
    onSelectChange: onSelectionChange,
    data,
    sort,
    sortChange,
    SELECTED_FIELD,
    idGetter,
    addSapphireEye,
    deleteSapphireEye,
    handleConfirmDelete,
    confirmDeleteMessage,
    onSelectionChange,
    onHeaderSelectionChange,
    organizationList,
    organization,
    organizationData,
    setOrganization,
    showDialog,
    showBulk,
    bulkRequest,
    setBulkRequest,
    toggleDialog,
    shouldDisableButton,
    shouldDisableEdit,
    toggleBulk,
    _setIsEdit: setIsEditing,
    accessToken,
    fetchData,
    pageSizeValue,
    pageChange,
    page,
    setData,
    setPageSizeValue,
    hasTruthyValue,
    parseSort,
    filter,
    handleFilter,
    handleBeforeBulkRequest,
    handleBulkRequest,
    excelDownload,
    editSapphireEye,
    isEditing,
    setIsEditing,
    setEditData,
    editData,
    isSelected,
    setIsSelected,
  }
}
