import React from 'react'
import './assets/css/_root.css'
import './assets/css/7signal.css'
import './App.scss'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DrawerComponent from './components/drawer'
import Eyes from './pages/Eyes'
import Instances from './pages/Instances'
import Organizations from './pages/Organizations'
import AccessTokenProvider from './commonResources/AccessTokenContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ProtectedComponent from './components/protected'
import LoaderComponent from './components/loader'
import CustomAuthenticationRequired from './shared/7Signal/CustomAuthenticationRequired'
import GlobalContextProvider from './commonResources/GlobalContextProvider'

const App: React.FC = () => {
  const minimumRequiredScopes = [
    'srs:eyes.read',
    'srs:instances.read',
    'srs:organizations.read',
  ]

  const routes = () => {
    return (
      <BrowserRouter>
        <DrawerComponent>
          <Routes>
            <Route path="/" element={<Eyes />} />
            <Route path="/eyes" element={<Eyes />} />
            <Route path="/instances" element={<Instances />} />
            <Route path="/organizations" element={<Organizations />} />
          </Routes>
        </DrawerComponent>
      </BrowserRouter>
    )
  }

  return (
    <div className="app__container" data-testid="auth0-provider">
      <ToastContainer
        toastStyle={{
          backgroundColor: '#003b62',
          color: 'white',
          width: '400px',
        }}
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        limit={1}
      />
      <AccessTokenProvider>
        <ProtectedComponent
          minimumRequiredScopes={minimumRequiredScopes}
          unauthorizedElement={<LoaderComponent showAccessMsg={true} />}
        >
          <GlobalContextProvider>{routes()}</GlobalContextProvider>
        </ProtectedComponent>
      </AccessTokenProvider>
    </div>
  )
}

export default CustomAuthenticationRequired(App)
