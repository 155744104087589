import React, { FC } from 'react'
import { useSevenSignalGridHook } from './Hooks'
import SevenSignalGridComponent from './Component'
import { SevenSignal } from './Types'

const SevenSignalGrid = (props: SevenSignal.GridProps) => {
  return (
    <SevenSignalGridComponent {...props} {...useSevenSignalGridHook(props)} />
  )
}
export default SevenSignalGrid
