import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { config } from './config'
import Loader from '../components/loader'
import { appScope$ } from '../services/app/app'

export const AccessTokenContext = createContext<any>(null)

const AccessTokenProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    ;(async () => {
      setAccessToken(
        await getAccessTokenSilently({
          authorizationParams: {
            audience: config.auth0.audience,
            scope: config.auth0.scopes,
          },
        })
      )

      appScope$.subscribe(async (hasValidToken) => {
        if (!hasValidToken) {
          setAccessToken(
            await getAccessTokenSilently({
              authorizationParams: {
                audience: config.auth0.audience,
                scope: config.auth0.scopes,
              },
            })
          )
        }
      })
    })()
  }, [getAccessTokenSilently])

  return useMemo(
    () => (
      <AccessTokenContext.Provider value={accessToken}>
        {children}
      </AccessTokenContext.Provider>
    ),
    [accessToken, children]
  )
}

export default withAuthenticationRequired(AccessTokenProvider, {
  onRedirecting: () => <Loader />,
})
