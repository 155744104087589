import { TextBoxProps } from '@progress/kendo-react-inputs'
import { useCallback, useEffect, useState } from 'react'
import {
  createSapphireEye,
  updateSapphireEyeById,
} from '../../../../services/eyes/eyes'
import { EyeDialogType } from './types'
import { getAllOrganizations } from '../../../../services/organizations/organizations'
import { OrganizationsProps } from '../../../Organizations/Types'
import Organization = OrganizationsProps.Organization

export const useEyeDialogHook = (props: any): EyeDialogType => {
  const [macAddress, setMacAddress] = useState<TextBoxProps['value']>('')
  const [serialNumber, setSerialNumber] = useState<TextBoxProps['value']>('')
  const [organization, setOrganization] = useState<Organization | undefined>()
  const [changes, setChanges] = useState<boolean>(false)
  const [organizationList, setOrganizationList] = useState<any>([])
  const handleChange = useCallback(
    (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const sapphireEyeObj = {
        macAddress: setMacAddress,
        serialNumber: setSerialNumber,
        organization: setOrganization,
      }
      const value = event.target.value
      if (value.length === 0) setChanges(false)
      if (sapphireEyeObj[field]) {
        sapphireEyeObj[field](value)
        setChanges(true)
      }
    },
    [setMacAddress, setSerialNumber]
  )

  const resetState = () => {
    setMacAddress('')
    setSerialNumber('')
    setOrganization(undefined)
    setChanges(false)
    props.clearCheckbox()
    props.toggleDialog()
  }

  const handleClear = useCallback((field: string) => {
    const sapphireEyeObj = {
      macAddress: () => setMacAddress(''),
      serialNumber: () => setSerialNumber(''),
      organization: () => setOrganization(undefined),
    }
    setChanges(false)
    sapphireEyeObj[field]()
  }, [])

  const create = async () => {
    const organizationId = organizationList.find(
      (org) => org.name === organization?.name
    )

    const data = {
      macAddress,
      serialNumber,
      organizationId: organizationId?.id,
    }
    await createSapphireEye(props.accessToken, data).catch((e) => {
      console.log('error: unable to create', e.message)
    })
    props.toggleDialog()
    resetState()
    props.fetchData()
    setChanges(false)
  }

  const update = async () => {
    const organizationId = organizationList.find(
      (org) => org.name === organization?.name
    )

    const validOrg = organizationId?.id ?? null
    const data = {
      macAddress,
      serialNumber,
      ...(validOrg !== null && { organizationId: validOrg }),
    }

    await updateSapphireEyeById(
      props.accessToken,
      props.editData.id,
      data
    ).catch((e) => {
      console.log('error: unable to update', e.message)
    })
    props.toggleDialog()
    resetState()
    props.fetchData()
    setChanges(false)
  }

  const organizationData = async () => {
    const response: any =
      (await getAllOrganizations(props.accessToken, 0, 1000, [`name,asc`])) ??
      []
    if (response?.data?.content?.length > 0) {
      const mappedData = response?.data?.content.map(({ id, name }) => ({
        id,
        name,
      }))
      mappedData.push({ id: null, name: 'No Organization Specified' })
      setOrganizationList(mappedData)
    }
  }

  useEffect(() => {
    if (props.isEditing) {
      const orgName =
        props.editData.organization.name !== null
          ? props.editData.organization
          : 'No Organization Specified'
      setMacAddress(props.editData.macAddress)
      setSerialNumber(props.editData.serialNumber)
      setOrganization(orgName)
    }
  }, [props.isEditing, props.editData])

  useEffect(() => {
    if (props.showDialog === true) {
      const fetchData = async () => {
        await organizationData()
      }
      fetchData()
    }
  }, [props.showDialog])

  return {
    fetchData: props.fetchData,
    accessToken: props.accessToken,
    showDialog: props.showDialog,
    toggleDialog: props.toggleDialog,
    clearCheckbox: props.clearCheckbox,
    macAddress,
    serialNumber,
    organization,
    handleClear,
    create,
    update,
    handleChange,
    changes,
    isEditing: props.isEditing,
    organizationList,
    resetState,
    setMacAddress,
    setSerialNumber,
    setOrganization,
    setChanges,
    organizationData,
  }
}
