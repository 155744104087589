import './styles.scss'
import { DropDownList } from '@progress/kendo-react-dropdowns'
interface DropDownListProps {
  data: any[]
  value: any
  onChange: (event) => void
  textField: string
}
const DropDownListComponent: React.FC<DropDownListProps> = ({
  data,
  value,
  onChange,
  textField,
}) => {
  return (
    <DropDownList
      data={data}
      value={value}
      defaultValue={value}
      onChange={onChange}
      textField={textField}
    />
  )
}

export default DropDownListComponent
