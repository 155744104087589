import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import '@progress/kendo-font-icons/dist/index.css'

export const useDrawerHook = () => {
  const [drawerVisible, setDrawerVisible] = useState(true)
  const logout = useAuth0().logout

  const drawerItems = [
    {
      text: 'Sapphire Eyes',
      icon: 'k-i-eye',
      route: '/eyes',
      className: 'drawer-item',
      selected: true,
    },
    {
      text: 'Organizations',
      icon: 'k-i-globe',
      route: '/organizations',
      className: 'drawer-item',
    },
    {
      text: 'Instances',
      icon: 'k-i-style-builder',
      route: '/instances',
      className: 'drawer-item',
    },
  ]
  const navigate = useNavigate()
  const location = useLocation()
  const handleDrawerToggle = () => {
    setDrawerVisible(!drawerVisible)
  }

  const setSelectedItem = (pathName) => {
    if (pathName === '/') {
      return 'Saphire Eyes'
    } else {
      let currentPath: any = drawerItems.find((item) => item.route === pathName)
      if (currentPath.text) {
        return currentPath.text
      }
    }
  }

  const selected = setSelectedItem(location.pathname)
  const handleSelect = (e) => navigate(e.itemTarget.props.route)
  return {
    drawerVisible,
    drawerItems,
    handleDrawerToggle,
    selected,
    handleSelect,
    logout: logout,
    _logout: logout,
    _setSelectItem: setSelectedItem,
  }
}
