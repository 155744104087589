import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import {
  InputClearValue,
  InputSeparator,
  TextBox,
} from '@progress/kendo-react-inputs'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@progress/kendo-react-buttons'
import './styles.scss'
import { OrganizationsProps } from 'pages/Organizations/Types'
import DropDownListComponent from '../../../../components/dropdown'
const DialogComponent = (props: OrganizationsProps.DialogType) => {
  return (
    <Dialog
      data-testid={'dialog'}
      className="organizations-dialog-test"
      height={500}
      width={450}
      onClose={() => props.toggleDialog}
    >
      <h2 className="dialog-header-text">
        {props.isEditing
          ? 'EDIT SAPPHIRE ORGANIZATION'
          : 'CREATE SAPPHIRE ORGANIZATION'}
      </h2>
      <form onSubmit={(e) => e.preventDefault()} data-testid={'form'}>
        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'org-name'}>Organziation Name</div>
          <TextBox
            data-testid={'org-txt-name'}
            size={'large'}
            fillMode={'flat'}
            value={props.name}
            onChange={(e) => {
              props.handleChange('name', e)
            }}
            placeholder="Ex: IBM-**"
            suffix={() => (
              <React.Fragment>
                {props.name !== '' && (
                  <InputClearValue onClick={() => props.handleClear('name')}>
                    <FontAwesomeIcon icon={faXmark} />
                  </InputClearValue>
                )}
                <InputSeparator />
              </React.Fragment>
            )}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'caratOrgName'}>Carat Org Name</div>
          <TextBox
            size={'large'}
            fillMode={'flat'}
            value={props.caratOrgName}
            onChange={(e) => {
              props.handleChange('caratOrgName', e)
            }}
            placeholder="Ex: 253"
            suffix={() => (
              <React.Fragment>
                {props.caratOrgName !== '' && (
                  <InputClearValue
                    onClick={() => props.handleClear('caratOrgName')}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </InputClearValue>
                )}
                <InputSeparator />
              </React.Fragment>
            )}
          />
        </fieldset>

        <fieldset className={'dialog-fieldset'}>
          <div data-testid={'instance'}>Instance</div>
          <DropDownListComponent
            data={props.instanceList}
            value={props.instance}
            textField={'hostname'}
            onChange={(e) => {
              props.handleChange('instance', e)
            }}
          />
        </fieldset>
      </form>
      <DialogActionsBar>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => {
            if (props.isEditing) {
              if (props.clearCheckbox) props.clearCheckbox()
              props.toggleDialog()
            } else {
              props.resetState()
            }
          }}
        >
          Cancel
        </Button>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={() => (props.isEditing ? props.update() : props.create())}
          disabled={!props.changes}
        >
          {props.isEditing ? 'Update' : 'Submit'}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default DialogComponent
