import React, { useCallback, useEffect } from 'react'
import {
  Auth0Context,
  Auth0ContextInterface,
  RedirectLoginOptions,
  useAuth0,
} from '@auth0/auth0-react'
import { ErrorPage } from '../Error'
import LoaderComponent from '../../../components/loader'

interface AuthenticationRequiredOptions {
  returnTo?: string | (() => string)
  onRedirecting?: () => JSX.Element
  onBeforeAuthentication?: () => Promise<void>
  loginOptions?: RedirectLoginOptions
  context?: React.Context<Auth0ContextInterface>
}
const defaultOnRedirecting = (): JSX.Element => (
  <LoaderComponent
    loadingPrimary="Redirecting"
    loadingSecondary="Redirecting for SSO Login"
  />
)
const defaultOnBeforeAuthentication = async (): Promise<void> => {
  /* noop */
}
const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`

const CustomAuthenticationRequired = <P extends object>(
  Component: React.ComponentType<P>,
  options: AuthenticationRequiredOptions = {}
): React.FC<P> => {
  return function AuthRequired(props: P): JSX.Element {
    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      onBeforeAuthentication = defaultOnBeforeAuthentication,
      loginOptions,
      context = Auth0Context,
    } = options

    const { isAuthenticated, isLoading, loginWithRedirect, error } =
      useAuth0(context)

    const handleAuthentication = useCallback(async () => {
      if (!error) {
        try {
          await onBeforeAuthentication()
          await loginWithRedirect({
            ...loginOptions,
            appState: {
              ...(loginOptions && loginOptions.appState),
              returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
            },
          })
        } catch (err: any) {
          console.error(err)
        }
      }
    }, [
      error,
      onBeforeAuthentication,
      loginWithRedirect,
      loginOptions,
      returnTo,
    ])

    useEffect(() => {
      if (!isLoading && !isAuthenticated && !error) {
        handleAuthentication()
      }
    }, [isLoading, isAuthenticated, handleAuthentication, error])

    useEffect(() => {
      if (error) {
        console.error(error)
      }
    }, [error])

    return (
      <>
        {error && <ErrorPage />}
        {isAuthenticated && <Component {...props} />}
        {!isLoading && !isAuthenticated && !error && onRedirecting()}
      </>
    )
  }
}

export default CustomAuthenticationRequired
