import React from 'react'
import './styles.scss'
import logo from '../../assets/svg/7SIGNAL.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Drawer, DrawerContent } from '@progress/kendo-react-layout'
import { Button } from '@progress/kendo-react-buttons'
import { useDrawerHook } from './hooks'

type DrawerComponentProps = {
  children: React.ReactNode
  logout: any
}
const DrawerComponent = (props: DrawerComponentProps | any) => {
  const drawerProps = useDrawerHook()
  return (
    <>
      <div className="drawer-header" data-testid="drawer-header">
        <FontAwesomeIcon
          icon={faBars}
          onClick={drawerProps.handleDrawerToggle}
          size={'lg'}
          className="drawer-icon"
        />
        <div className="app__logo-mark">
          <img className="app__logo" src={logo} alt="7 Signal" />
          <span className="app__name">REDIRECTOR</span>
        </div>
        <Button
          className="logout-btn"
          fillMode="flat"
          themeColor={'light'}
          onClick={() => {
            drawerProps.logout({
              logoutParams: { returnTo: window.location.origin },
            })
          }}
          data-testid="logout-button"
        >
          LOG OUT
        </Button>
      </div>
      <Drawer
        expanded={drawerProps.drawerVisible}
        position="start"
        mode="push"
        mini={true}
        items={drawerProps.drawerItems.map((item) => ({
          ...item,
          selected: item.text === drawerProps.selected,
        }))}
        onSelect={drawerProps.handleSelect}
      >
        <DrawerContent className="drawer-content">
          {props.children}
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default DrawerComponent
