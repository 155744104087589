import React from 'react'
import './styles.scss'
import { Button } from '@progress/kendo-react-buttons'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Upload } from '@progress/kendo-react-upload'
import { config } from '../../commonResources/config'
import { System } from '../../pages/Instances/Types'
import DropDownListComponent from '../../components/dropdown'
import { SapphireEyeHelper } from '../../pages/Eyes/Hooks'

const BulkUploadComponent = (props) => {
  const uploadUrl = `${config.hostUrl}/api-v1/${System.Eye}/upload?organizationId=${SapphireEyeHelper.bulkRequestId}`

  return (
    <Dialog
      data-testid={'eyes-bulk'}
      height={600}
      width={450}
      onClose={() => props?.toggleDialog}
    >
      <h2 className="eye-dialog-header-text">BULK EYE UPLOAD</h2>
      <div className="bulk_org" data-testid={'organization'}>
        1. Choose Organization
      </div>
      <DropDownListComponent
        data-testid={'eyes-bulk-dropdown'}
        data={props.organizationList}
        textField={'name'}
        value={props.organization || ''}
        onChange={(e) =>
          props.handleChange(e.target.value, props.organizationList)
        }
      />

      <div className="bulk_org" data-testid={'organization'}>
        2. Upload CSV
      </div>
      <Upload
        className="bulk_upload"
        batch={false}
        restrictions={{
          allowedExtensions: ['.csv'],
          maxFileSize: 4000000,
        }}
        multiple={true}
        defaultFiles={[]}
        withCredentials={false}
        saveUrl={uploadUrl}
        onBeforeUpload={(e) => props.handleBeforeBulkRequest(e)}
        onStatusChange={(e) => props.handleBulkRequest(e)}
      />

      {props?.bulkRequest?.hasError && (
        <div className="bulk_error">{props?.bulkRequest?.msg}</div>
      )}

      <DialogActionsBar>
        <Button
          className={'dialog-btn'}
          size={'large'}
          fillMode={'solid'}
          onClick={props?.toggleBulk}
        >
          Cancel
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}

export default BulkUploadComponent
