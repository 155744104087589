// Adds the "env" object to the window object, but this shouldn't be used in the code,
// instead we should import this file and use the exported variables
declare global {
  interface Window {
    env: any
  }
}

// Capture the contents of window.env (if it exists)
// and then clear it out so that it can't be interrogated
// This probably isn't important, but would prevent an
// unauthorized script from capturing these values.
window.env = window.env || {}
const envCopy = window.env
window.env = {}

function getFromEnvironment(key: string): string {
  return process.env[key] ?? envCopy[key]
}

export const config = {
  hostUrl: getFromEnvironment('REACT_APP_HOST_URL'),
  auth0: {
    domain: getFromEnvironment('REACT_APP_AUTH0_DOMAIN'),
    clientId: getFromEnvironment('REACT_APP_AUTH0_CLIENTID'),
    scopes: getFromEnvironment('REACT_APP_AUTH0_SCOPES'),
    audience: getFromEnvironment('REACT_APP_AUTH0_TOKEN_AUDIENCE'),
  },
}
