import React from 'react'
import { SevenSignal } from './Types'
import {
  Grid,
  GridFilterChangeEvent,
  GridFilterOperators,
  GridToolbar,
} from '@progress/kendo-react-grid'
import { Button, ButtonGroup } from '@progress/kendo-react-buttons'
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn'
import debounce from 'lodash.debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SevenSignalGridComponent = (props: SevenSignal.GridProps) => {
  const filterOperators: GridFilterOperators = {
    text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
    numeric: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
    date: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
    boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
  }

  return (
    <>
      {props.data && (
        <Grid
          cellRender={props.customCellRender}
          headerCellRender={props.customHeaderCellRender}
          pageable={true}
          scrollable="scrollable"
          sortable={{
            allowUnsort: true,
            mode: 'single',
          }}
          sort={props.sort}
          skip={props.skip}
          take={props.take}
          total={props.total}
          onSortChange={props.sortChange}
          data={props.data?.map((item) => ({
            ...item,
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
          }))}
          dataItemKey={'id'}
          rowHeight={50}
          selectedField={props.selectedField}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: 'multiple',
          }}
          onSelectionChange={props.onSelectionChange}
          onHeaderSelectionChange={props.onHeaderSelectionChange}
          onPageChange={props.onPageChange}
          filterable={props.filterable}
          filter={props.filter}
          filterOperators={filterOperators}
          onFilterChange={debounce(
            (e: GridFilterChangeEvent) => props.handleFilter(e.filter),
            100
          )}
        >
          <GridToolbar className="eyes-btn-container">
            <ButtonGroup
              className="left-btn-group"
              data-testid={'left-button-group'}
            >
              {props.leftTableButtons &&
                props.leftTableButtons.map(
                  (btn: SevenSignal.TableButtons, idx) => (
                    <Button
                      title={btn.title}
                      onClick={btn.onClick}
                      className={btn.className}
                      key={idx}
                      disabled={btn?.disabled ?? false}
                    >
                      {btn.hasFontAwesomeIcon && (
                        <FontAwesomeIcon
                          icon={btn.icon}
                          className={btn.iconClassName}
                        />
                      )}
                      {btn.name}
                    </Button>
                  )
                )}
            </ButtonGroup>
            <ButtonGroup
              className="right-btn-group"
              data-testid={'right-button-group'}
            >
              {props.rightTableButtons &&
                props.rightTableButtons.map((btn, idx) => (
                  <Button
                    title={btn.title}
                    onClick={btn.onClick}
                    className={btn.className}
                    key={idx}
                    disabled={btn?.disabled ?? false}
                  >
                    {btn.hasFontAwesomeIcon && (
                      <FontAwesomeIcon
                        icon={btn.icon}
                        className={btn.iconClassName}
                      />
                    )}
                    {btn.name}
                  </Button>
                ))}
            </ButtonGroup>
          </GridToolbar>
          {props.columns.map((column, idx) => (
            <Column
              key={idx}
              field={column.field}
              title={column.title}
              width={column.width ?? 'auto'}
              filterable={column.filterable ?? true}
              headerSelectionValue={column.headerSelectionValue ?? false}
            />
          ))}
        </Grid>
      )}
    </>
  )
}

export default SevenSignalGridComponent
