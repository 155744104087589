import { useCallback, useContext, useEffect, useState } from 'react'
import { getter, SortDescriptor } from '@progress/kendo-data-query'
import {
  getSelectedState,
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import { AccessTokenContext } from '../../commonResources/AccessTokenContext'
import { OrganizationsProps } from './Types'
import {
  deleteOrganizationById,
  getAllOrganizations,
} from '../../services/organizations/organizations'
import { asyncCurryHelper, orgCheckboxChanged$ } from '../../services/app/app'

export const useOrganizationsHook = (): OrganizationsProps.Component => {
  const accessToken = useContext<string | null>(AccessTokenContext)
  OrganizationsProps.Helper.hasAccessToken = accessToken !== null
  const [data, setData] = useState<OrganizationsProps.Organization[]>([
    {
      id: '',
      name: '',
      instance: {
        id: '',
        hostname: '',
      },
      caratOrgName: '',
    },
  ])
  const setSearchOptions = (field: String, newValue: any) => {
    const idx = OrganizationsProps.Helper.filters.findIndex(
      (item) => item.field === field
    )
    if (idx !== -1) OrganizationsProps.Helper.filters.splice(idx, 1, newValue)
    if (idx === -1) OrganizationsProps.Helper.filters.push(newValue)
  }
  const [sort, setSort] = useState<Array<SortDescriptor>>([
    { field: '', dir: 'asc' },
  ])
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[]
  }>({})
  const [page, setPage] = useState<any>({ take: 20, page: 0 })
  const [pageSizeValue, setPageSizeValue] = useState<number>(0)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<any>({})
  const [editData, setEditData] = useState<OrganizationsProps.Organization>({
    id: '',
    name: '',
    instance: {
      id: '',
      hostname: '',
    },
    caratOrgName: '',
  })
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState('')
  const DATA_ITEM_KEY: string = 'id'
  const SELECTED_FIELD: string = 'selected'
  const idGetter = getter(DATA_ITEM_KEY)
  const [isSelected, setIsSelected] = useState<any>(null)
  const parseSort = (sort) => {
    return sort.map((item) => {
      const field = item.field === 'instance.hostname' ? 'hostname' : item.field
      return `${field},${item.dir}`
    })
  }
  const sortChange = async (event: GridSortChangeEvent) => {
    let response
    event.sort.forEach((item) => {
      setSearchOptions('sort', {
        field: 'sort',
        value: [
          {
            field: item.field,
            value: item.dir,
          },
        ],
      })
    })
    try {
      if (
        OrganizationsProps.Helper.filters.some((item) => item.field === 'page')
      ) {
        response = await asyncCurryHelper(getAllOrganizations)(
          accessToken,
          OrganizationsProps.Helper.filters.find(
            (item) => item.field === 'page'
          ).value,
          20,
          parseSort(event.sort)
        )
      } else {
        response = await asyncCurryHelper(getAllOrganizations)(
          accessToken,
          page.page,
          page.take,
          parseSort(event.sort)
        )
      }

      setData(response?.data.content)
      setSort(event.sort)
    } catch (error) {
      console.log('Unable to sort Sapphire Organizations:', error)
    }
  }
  const fetchData = async () => {
    try {
      if (OrganizationsProps.Helper.hasAccessToken) {
        const sortData = parseSort(sort)
        const response = await getAllOrganizations(
          accessToken,
          page.page,
          page.take,
          sortData
        )
        setData(response?.data.content)
        setPageSizeValue(response?.data.pagination.total)
      }
    } catch (error) {
      console.error('Error fetching organizations:', error)
    }
  }
  useEffect(() => {
    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const addOrganization = () => {
    setIsEditing(false)
    setSelectedState({})
    setShowDialog(true)
  }

  const editOrganization = () => {
    setShowDialog(true)
    setIsEditing(true)
  }

  const deleteOrganization = async () => {
    try {
      const id = OrganizationsProps.Helper.itemsToDelete[0].id
      await deleteOrganizationById(accessToken, id)
      fetchData()
      setSelectedState({})
    } catch (error) {
      console.error('Error deleting organization:', error)
    }
  }

  const handleConfirmDelete = (objectData) => {
    let newDeleteMessage = 'Continue to delete ' + objectData.name + '?'
    setConfirmDeleteMessage(newDeleteMessage)
  }

  const normalizeSort = (sort: SortDescriptor[]) => {
    if (sort[0]?.field) {
      const field =
        sort[0]?.field === 'instance.hostname' ? 'hostname' : sort[0]?.field
      return `${field},${sort[0]?.dir}`
    } else {
      return 'asc'
    }
  }

  const pageChange = async (e) => {
    const page = Math.floor(e.page.skip / e.page.take)
    const pageSort = normalizeSort(sort)
    const response = await getAllOrganizations(accessToken, page, 20, pageSort)

    setData(response?.data?.content)
    setPageSizeValue(response?.data?.pagination.total)
    setPage({
      ...e.page,
      take: 20,
    })
    setSearchOptions('page', {
      field: 'page',
      value: page,
    })
  }

  const toggleDialog = useCallback(() => {
    setShowDialog(!showDialog)
  }, [showDialog])

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      })
      OrganizationsProps.Helper.itemsToDelete = [event.dataItem]
      setSelectedState(newSelectedState)
      setEditData(event.dataItem)
    },
    [selectedState, idGetter]
  )

  const hasTruthyValue = (obj) => {
    const array: any = Object.values(obj).filter(Boolean)
    return array.length === 1
  }

  const shouldDisableButton = () => {
    return !hasTruthyValue(selectedState)
  }

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target
      const checked = checkboxElement.checked
      const newSelectedState = {}

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked
      })

      OrganizationsProps.Helper.itemsToDelete = event.dataItems
      setSelectedState(newSelectedState)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    orgCheckboxChanged$.subscribe((e) => {
      setIsCheckboxSelected({
        id: e?.dataItem?.id,
        isChecked: e?.isChecked,
      })
      if (e?.isChecked) {
        onSelectionChange({
          ...e?.dataItem,
          dataItems: e?.dataItems,
          dataItem: e?.dataItem,
          dataItemKey: e?.datatItem?.id,
        })
      } else {
        OrganizationsProps.Helper.itemsToDelete = []
      }
    })
  }, [])

  useEffect(() => {}, [toggleDialog, setIsSelected])
  return {
    onSelectChange: undefined,
    data,
    sort,
    sortChange,
    selectedState,
    SELECTED_FIELD,
    idGetter,
    addOrganization,
    deleteOrganization,
    handleConfirmDelete,
    confirmDeleteMessage,
    onSelectionChange,
    onHeaderSelectionChange,
    showDialog,
    toggleDialog,
    shouldDisableButton,
    accessToken,
    fetchData,
    pageSizeValue,
    pageChange,
    page,
    setData,
    setPageSizeValue,
    hasTruthyValue,
    parseSort,
    setSelectedState,
    editOrganization,
    isEditing,
    setIsEditing,
    setEditData,
    editData,
    isSelected,
    setIsSelected,
    isCheckboxSelected,
    setIsCheckboxSelected,
  }
}
