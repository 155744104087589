import { BehaviorSubject } from 'rxjs'

/*
 * WARNING: - Read below for better understanding, the function may be a bit advanced but worth it!
 *
 * @param {function} fn - the function you want to call
 *
 * @param {...any} args - this is the properties you want the function to be called with followed by comma separator
 *
 * @returns void
 *
 * @description - This is a curry function,
 * which is essentially nothing more than a
 * function that returns another function.
 * For example, if you have multiple functions that need
 * to perform essentially the same task, the curryHelper allows
 * you to pass in the function you want it to call.
 * The second parameter specifies the properties you want
 * the function to be called with.
 *
 * @example: -> curryHelper(createInstance)(accToken)
 * This means that the function `createInstance` will be called with `accToken` as it's parameter/property
 */
export const parseSort = (sort) => {
  return sort.map((item) => {
    const field =
      item.field === 'organization.name' ? 'organizationName' : item.field
    return `${field},${item.dir}`
  })
}

export const asyncCurryHelper = (fn) => {
  return async (...args) => {
    return await fn(...args)
  }
}

export const appScope$ = new BehaviorSubject<any>(false)
export const notify$ = new BehaviorSubject<any>('')
export const instanceCheckboxChanged$ = new BehaviorSubject<any>({})
export const orgCheckboxChanged$ = new BehaviorSubject<any>({})
