/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios'
import { System } from '../../pages/Instances/Types'
import { toast } from 'react-toastify'
import { config } from '../../commonResources/config'
import { errorHandler, showErrorToast } from '../error/error'
import { parseSort } from '../app/app'

const baseUrl = `${config.hostUrl}/api-v1`

export const getAllSapphireEyes = async (
  accessToken: string | null,
  page: number,
  size: number,
  sort?: any
) => {
  try {
    return await axios.get(
      `${baseUrl}/${System.Eye}?page=${page}&size=${size}&sort=${sort}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
  } catch (error: any) {
    if (error?.response?.status === 401) {
      errorHandler('Unauthorized', error)
    }
    showErrorToast(error, 'Error fetching eyes')
  }
}

export const getSapphireEyeById = async (accessToken: any, id: string) => {
  return await axios.get(`${baseUrl}/${System.Eye}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const getSapphireEyesWithOptions = async (
  accessToken: string | null,
  queryParams: any,
  page: any,
  sort: any
) => {
  let url = ''
  if (queryParams !== undefined) {
    queryParams.forEach((q) => {
      if (q.value) {
        url += `${q.field === 'organization.name' ? 'organizationName' : q.field}=${
          q.value ?? q.dir
        }&`
      }
    })

    if (page) {
      url += `page=${page.page}&size=${page.take}`
    }

    if (sort) {
      const sortParams = parseSort(sort)
      url += `&sort=${sortParams}`
    }

    if (url !== '') {
      try {
        return await axios.get(`${baseUrl}/${System.Eye}?${url}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      } catch (error) {
        console.error('Error:', error)
        showErrorToast(error, 'Error fetching eyes')
      }
    }
  } else {
    try {
      return await axios.get(`${baseUrl}/${System.Eye}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    } catch (error) {
      console.error('Error:', error)
      showErrorToast(error, 'Error fetching eyes')
    }
  }
}

export const createSapphireEye = async (
  accessToken: string | null,
  data: any
) => {
  try {
    const response = await axios.post(`${baseUrl}/${System.Eye}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    toast.success('Successfully created Eye', { toastId: 'success' })
    return response
  } catch (error: any) {
    console.error('Error creating SapphireEye:', error)
    showErrorToast(error, 'Error creating eye')
  }
}

export const deleteSapphireEyeById = async (
  accessToken: string | null,
  id: string
) => {
  try {
    const response = await axios.delete(`${baseUrl}/${System.Eye}/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    toast.success('Successfully deleted Sapphire Eye', { toastId: 'success' })
    return response
  } catch (error) {
    console.error('Error:', error)
    showErrorToast(error, 'Error deleting eye')
  }
}

export const updateSapphireEyeById = async (
  accessToken: string | null,
  id: string,
  data
) => {
  try {
    const response = await axios.put(
      `${baseUrl}/${System.Eye}/${id}`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    toast.success('Successfully updated Sapphire Eye', { toastId: 'success' })
    return response
  } catch (error) {
    console.error('Error with PUT request:', error)
    showErrorToast(error, 'Error updating eye')
  }
}
