import React, { useContext, useEffect } from 'react'
import './styles.scss'
import { useJwt } from 'react-jwt'
import { AccessTokenContext } from '../../commonResources/AccessTokenContext'

const checkAppScopes = (scope: string, minimumRequiredScopes: [string]) => {
  const minimumScope: any = []
  if (scope) {
    scope.split(' ').forEach((scope) => {
      if (minimumRequiredScopes) {
        if (minimumRequiredScopes.includes(scope)) {
          minimumScope.push(scope)
        }
      }
    })
  }
  return minimumScope.length >= minimumRequiredScopes.length
}

const ProtectedComponent: any = ({
  minimumRequiredScopes,
  children,
  unauthorizedElement,
}) => {
  const token = useContext(AccessTokenContext)
  const { decodedToken }: any = useJwt(token ?? '')
  const [appScopes, setAppScopes] = React.useState<boolean>(false)

  useEffect(() => {
    if (decodedToken?.scope)
      setAppScopes(checkAppScopes(decodedToken?.scope, minimumRequiredScopes))
  }, [decodedToken])

  return <>{appScopes ? children : unauthorizedElement}</>
}

export default ProtectedComponent
