import { useCallback, useState } from 'react'

export const useConfirmHook = () => {
  const [confirmIsVisible, setConfirmIsVisible] = useState(false)

  const handleIsVisibleToggle = () => {
    setConfirmIsVisible(!confirmIsVisible)
  }

  const handleAbort = useCallback((abortCallback: any) => {
    abortCallback()
  }, [])

  const handleConfirm = useCallback((confirmCallback: any) => {
    confirmCallback()
  }, [])

  return {
    confirmIsVisible,
    handleAbort,
    handleConfirm,
    handleIsVisibleToggle,
  }
}
