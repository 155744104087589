export namespace OrganizationsProps {
  export interface Instance {
    id: string
    hostname: string
  }
  export interface Organization {
    id: string
    instance: Instance
    caratOrgName: string
    name: string
  }

  export interface OrganizationPUT {
    instanceId: any
    caratOrgName: any
    name: any
  }

  export interface Component {
    data: Organization[]
    sort: any
    sortChange: any
    SELECTED_FIELD: any
    selectedState: any
    setSelectedState?: any
    idGetter: any
    onSelectChange: any
    onSelectionChange: any
    onHeaderSelectionChange: any
    addOrganization: any
    deleteOrganization: any
    handleConfirmDelete: any
    confirmDeleteMessage: string
    setConfirmDeleteMessage?: any
    showDialog: any
    toggleDialog: () => any
    shouldDisableButton: any
    fetchData: () => any
    accessToken: any
    pageSizeValue: number
    pageChange: (e) => any
    page: any
    isEditing?: boolean
    setEditData?: any
    setIsEditing?: any
    setPageSizeValue?: any
    selectionMode?: any
    setData?: any
    hasTruthyValue?: any
    parseSort?: (e) => any
    editOrganization: any
    editData: Organization
    isSelected?: any
    setIsSelected?: any
    isCheckboxSelected?: any
    setIsCheckboxSelected?: any
  }

  export interface DialogType {
    instance?: any
    setInstance?: any
    caratOrgName: any
    name: any
    handleClear: (field: any) => any
    toggleDialog: () => any
    fetchData: () => any
    handleChange: any
    accessToken: any
    changes: boolean
    create: any
    isEditing: boolean
    showDialog: boolean
    resetState: () => any
    update: any
    setCaratOrgName?: any
    setName?: any
    setChanges?: any
    instanceList?: any
    instanceData?: any
    clearCheckbox?: () => any
  }

  export const Helper: any = {
    itemsToDelete: [],
    itemPutID: '',
    pagination: {
      page: 0,
      pages: 1,
      perPage: 20,
      total: 20,
    },
    hasAccessToken: false,
    filters: [],
  }
}
